import { h, resolveComponent } from 'vue';

const emptyLayout = { render: () => h(resolveComponent('router-view')) };

export default {
    path: '/p',
    name: 'p-home',
    component: emptyLayout,
    children: [
        {
            path: 'test',
            name: 'p-test',
            component: () => import('@/views/pc/test.vue')
        },
        {
            path: '/live-manage/:bno?',
            name: 'live-mange',
            component: () => import('@/views/pc/manage/index.vue')
        },
        {
            path: '/live-view/:bno?',
            name: 'p-view',
            component: () => import('@/views/pc/ViewPage/index.vue')
        },
        {
            path: 'admin',
            name: 'p-admin',
            component: () => import('@/views/pc/AdminPage/index.vue')
        },
        {
            path: 'home',
            name: 'p-home',
            component: () => import('@/views/pc/HomePage/index.vue')
        },
        {
            path: 'live',
            name: 'p-live',
            component: () => import('@/views/pc/HomePage/liveList.vue')
        }
    ]
};
