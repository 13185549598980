/**
 * @Description: 页面活动的时候，轮循查询版本是否更新，并提示用户更新。适用于覆盖式更新机制
 * @Document 参考：https://juejin.cn/post/6910395895485825037
 * @Server 适用部署环境： 已验证：IIS服务器、nginx服务器
 * @Author Gfy
 * @date 2023/4/25
 */
import {ElMessageBox} from 'element-plus';

// 用于记录时间戳的变量，时间戳是响应头中的etag和last-modified字段其中之一
let previousTimeTag: string;
let versionMonitor: any | undefined;
let pageIsWake = true;
let waitConfirm = false;
// 检测间隔时长，单位秒，默认15分钟
const checkInterval = 60 * 2;
// 上次检测的时间
let lastCheckTime = 0;
/***
 * 获取最新的etag
 */
const getTimeTag = async (): Promise<string> => {
  const response = await fetch(`${window.location.protocol}//${window.location.host}`, {
    method: 'HEAD',
    cache: 'no-cache'
  })
  // 以响应体的etag或者last-modified为时间戳
  return (response.headers.get('etag') || response.headers.get('last-modified')) + ''
}

// 通过请求检测版本
const judge = async (): Promise<void> => {
  // 获取当前最新的时间戳
  const currentTimeTag: string = await getTimeTag();
  // 记录首次请求的时间戳，以便与后面轮询得出的时间戳进行对比
  if (!previousTimeTag) {
    previousTimeTag = currentTimeTag;
  } else if (previousTimeTag !== currentTimeTag) {  // 检测到最新请求的时间戳和上一次不一致，即文件发生变化
    waitConfirm = true;
    // console.log("previousTimeTag:", previousTimeTag, "currentTimeTag:", currentTimeTag);
    // 更新提示
    ElMessageBox.confirm("发现新版本, 是否更新?", "提示", {
      closeOnClickModal: false,
      closeOnPressEscape: false,
      closeOnHashChange: false,
      showClose: false,
      confirmButtonText: "更新",
      cancelButtonText: `30分钟后提醒我`,
      type: "warning",
    }).then(() => {
      previousTimeTag = currentTimeTag;
      waitConfirm = false;
      location.reload();
    }).catch(() => {
      lastCheckTime = new Date().getTime() + 30 * 60 * 1000;
      waitConfirm = false;
    });
  }
}

// 轮循检测版本
async function checkVersion() {
  // console.log("pageIsWake:", pageIsWake);
  if (pageIsWake) {
    if (!waitConfirm) {
      const nowTimeStamp = new Date().getTime();
      if (nowTimeStamp - lastCheckTime > 1000 * checkInterval) {
        lastCheckTime = nowTimeStamp;
        await judge();
      }
    }

    versionMonitor = setTimeout(() => {
      checkVersion();
    }, checkInterval * 1000);
  } else {
    versionMonitor && clearTimeout(versionMonitor);
  }
}


// 如果页面是隐藏状态，则暂停检测；如果页面是展示状态，则继续检测
function handleVisibilityChange() {
  pageIsWake = !document.hidden;
  // console.log("handleVisibilityChange:", pageIsWake);
  pageIsWake && checkVersion();
}

/**
 * 页面激活和隐藏 调整检测版本
 */
function addVisibilityListener(): void {
  // 如果浏览器不支持addEventListener 或 Page Visibility API 给出警告
  if (typeof document.addEventListener === "undefined" || typeof document.hidden === "undefined") {
    console.log("This function requires a browser, such as Google Chrome or Firefox, that supports the Page Visibility API.");
  } else {
    // 处理页面可见属性的改变
    document.addEventListener("visibilitychange", handleVisibilityChange, false);
  }
}

// 仅在生产环境中调用
if (process.env.NODE_ENV === "production") {
  addVisibilityListener();
  checkVersion(); // 轮循更新版本处理
}


