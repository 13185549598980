import { h, resolveComponent } from 'vue';

const emptyLayout = { render: () => h(resolveComponent('router-view')) };

export default {
    path: '/m',
    name: 'm-home',
    component: emptyLayout,
    children: [
        {
            path: 'test',
            name: 'm-test',
            component: () => import('@/views/mobile/test.vue')
        },
        {
            path: 'live-view/:bno?',
            name: 'm-view',
            component: () => import('@/views/mobile/ViewPage/index.vue')
        }
    ]
};