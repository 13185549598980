import router from '@/router'
import NProgress from 'nprogress';
// import {useUserStore} from '@/store'
import {isMobile} from "@/scripts/utils"
import {RouteLocationNormalized, NavigationGuardNext} from 'vue-router';


//路由进入前拦截
//to:将要进入的页面 vue-router4.0 不推荐使用next()
const whiteList = ['/login', '/404', '/401'] // no redirect whitelist
router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext): Promise<any> => {
  NProgress.start();
  // 直播观看路由，自动根据环境切换路由地址
  if (to.path.indexOf("/live-view") > -1) {
    const arr = to.fullPath.split("/live-view");
    if (isMobile()) {
      if (to.path.indexOf("/m/live-view") > -1) {
        next();
      } else {
        next({
          query: to.query,
          path: `/m/live-view${arr.length > 1 ? arr[1] : ""}`,
          // replace: true
        })
      }
    } else {
      if (to.path.indexOf("/m/live-view") === -1) {
        next();
      } else {
        next({
          query: to.query,
          path: `/live-view${arr.length > 1 ? arr[1] : ""}`,
          // replace: true
        });
      }
    }
  } else {
    next();
  }
  // const basicStore = useUserStore()

  // //1.判断token
  // if (basicStore.token) {
  //     if (to.path === '/login') {
  //         return '/'
  //     } else {
  //         // //2.判断是否获取用户信息
  //         // if (!basicStore.getUserInfo) {
  //         //     try {
  //         //         const userData = await userInfoReq()
  //         //         //3.动态路由权限筛选
  //         //         filterAsyncRouter(userData)
  //         //         //4.保存用户信息到store
  //         //         basicStore.setUserInfo(userData)
  //         //         //5.再次执行路由跳转
  //         //         return { ...to, replace: true }
  //         //     } catch (e) {
  //         //         console.error(`route permission error${e}`)
  //         //         basicStore.resetState()
  //         //         progressClose()
  //         //         return `/login?redirect=${to.path}`
  //         //     }
  //         // } else {
  //         //     return true
  //         // }
  //         return true;
  //     }
  // } else {
  //     if (!whiteList.includes(to.path)) {
  //         return `/login?redirect=${to.path}`
  //     } else {
  //         return true
  //     }
  // }
})

//路由进入后拦截
router.afterEach(() => {
  NProgress.done();
})