import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import HomeView from '../views/home.vue';

const pc = () => import("../views/layout/pc.vue");

const pcRouteList: RouteRecordRaw[] = [];
const modules = require.context("./pc", false, /\.ts$/);
modules.keys().forEach((fileName: any) => {
  // 将模块内的路由重新组装
  pcRouteList.push(modules(fileName).default);
});

const mobile = () => import("../views/layout/mobile.vue");
const mobileRouteList: RouteRecordRaw[] = [];
const mobileModules = require.context("./mobile", false, /\.ts$/);
mobileModules.keys().forEach((fileName: any) => {
  // 将模块内的路由重新组装
  mobileRouteList.push(mobileModules(fileName).default);
});

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    redirect: "/p/home",
    children: [
      {
        path: "/mobile",
        name: "mobile",
        component: mobile,
        children: [
          ...mobileRouteList
        ]
      },
      {
        path: "/pc",
        name: "pc",
        component: pc,
        children: [
          ...pcRouteList
        ]
      },
    ]
  },
  { path: "/:pathMatch(.*)", redirect: "/" }
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
