export function clone(obj: any): any {
  return JSON.parse(JSON.stringify(obj));
}

/**
 * 字符串转json，失败返回字符串
 * @param str
 * @return {any}
 */
export const strConvertJson: any = (str: any): any => {
  try {
    if (typeof JSON.parse(str) === "object") {
      return JSON.parse(str);
    }
  } catch (e) {}
  return str;
};

/**
 * 获取当前时间(格式yyyy-mm-dd hh:MM:ss)
 */
export function getNowDateTime(): string {
  const date = new Date();
  const Y = date.getFullYear() + "-";
  const M = (date.getMonth() + 1 < 10 ? "0" + (date.getMonth() + 1) : date.getMonth() + 1) + "-";
  const D = (date.getDate() < 10 ? "0" + date.getDate() : date.getDate()) + " ";

  const h = (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
  const m = (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) + ":";
  const s = date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
  return Y + M + D + h + m + s;
}
/**
 * 转换时间格式 年月日
 */
export function formatDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
}
/**
 * 判断当前设备是否移动端
 */
export function isMobile(): RegExpMatchArray | null {
  return navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  );
}

/**
 * 时间转为秒
 * @param time 时分秒字符串, 格式hh:mm:ss
 */
export const timeStrToSecond = (time: string): number => {
  const hour = Number(time.split(":")[0]);
  const min = Number(time.split(":")[1]);
  const sec = Number(time.split(":")[2]);
  const s: number = Number(hour * 3600) + Number(min * 60) + Number(sec);
  return s;
};

/**
 * 将秒转化成时分秒
 * @param s 秒
 */
export const secondToTimeStr = (s: number): string => {
  let t = "";
  if (s > -1) {
    const hour = Math.floor(s / 3600);
    const min = Math.floor(s / 60) % 60;
    const sec = s % 60;
    t += `${hour < 10 ? "0" : ""}${hour}:${min < 10 ? "0" : ""}${min}:${sec < 10 ? "0" : ""}${sec}`;
  }
  return t;
};
