import { ComponentPublicInstance, createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import "amfe-flexible";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import { ElMessage } from "element-plus";
import "@/assets/styles/index.scss";
// 引入iconfont
import "@/assets/iconfonts/iconfont.css";
import store from "./store";
// import * as directives from '@/directives'
import "@/permission";
import "@/scripts/version"; // 部署更新缓存检测

// ElementPlus时间使用中文环境
import ElementPlus from "element-plus";
import locale from "element-plus/lib/locale/lang/zh-cn";
import dayjs from "dayjs";
import "dayjs/locale/zh-cn";
dayjs.locale("zh-cn");

const app = createApp(App);
// 注册elementplus icon图标
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
// // 自定义指令
// Object.keys(directives).forEach(key => {
//     app.directive(key, (directives as { [key: string]: Directive })[key])
// })
app.config.globalProperties.$message = ElMessage;
// app.config.errorHandler = (err: unknown, vm: ComponentPublicInstance | null, info: string): void => {
//   console.error("errorHandler:", err, info);
// }

app.use(router).use(store).use(ElementPlus, { locale }).mount("#app");

// fetch(`${window.location.protocol}//${window.location.host}`, {
//   method: 'HEAD',
//   cache: 'no-cache'
// }).then(response => {
//   // 以响应体的etag或者last-modified为时间戳
//   const tt = response.headers.get('etag') || response.headers.get('last-modified')
//   console.log(response, tt);
// });
