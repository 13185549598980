import {defineStore} from 'pinia';
import {logout as userLogout, getUserProfile} from '@/api/user/index';
import {Api} from '@/typings/api.js';
// import { setToken, clearToken } from '@/utils/auth';
import {localStg} from '@/scripts/store';

export type RoleType = '' | '*' | 'admin' | 'user';

export interface UserState {
  token?: string | undefined;
  phone?: string | undefined;
  bno?: string | undefined;
  user?: Api.User;
  org?: Api.ORG;
  livePwd?: string | undefined;
}

export const useUserStore = defineStore('user', {
  state: (): UserState => ({
    token: localStg.get("token") || '',
    user: localStg.get("user") || undefined,
    phone: localStg.get("phone") || '',
    bno: localStg.get("bno") || '',
    org: localStg.get("org") || undefined,
    livePwd: localStg.get("livePwd") || '',
  }),
  getters: {
    userProfile(state: UserState): UserState {
      return {...state};
    },
    tokenValue(state: UserState): string {
      return state?.token || "";
    },
    livePwdValue(state: UserState): string {
      return state?.livePwd || "";
    },
  },
  actions: {
    // switchRoles() {
    //     return new Promise((resolve) => {
    //         this.role = this.role === 'user' ? 'user' : 'admin';
    //         resolve(this.role);
    //     });
    // },
    // 设置用户的信息
    setInfo(partial: Partial<UserState>) {
      this.$patch(partial);
    },
    // 重置用户信息
    resetInfo() {
      this.$reset();
    },
    // 获取用户信息
    async info() {
      const result = await getUserProfile();
      if (result) this.setInfo(result);
    },
    // 异步登录并存储token
    login(dataInfo: Api.Certificate, bno: string): void {
      const {Token, User, Orgs} = dataInfo;
      // const token = result?.token;
      if (Token) {
        localStg.set("token", Token);
        localStg.set("user", User || {});
        localStg.set("phone", User?.Telephone || "");
        localStg.set("bno", bno || "");
        localStg.set("org", Orgs?.length ? Orgs[0] : null);
        this.resetInfo();
      }
    },
    setLivePwd(pwd: string): void {
      if (pwd) {
        localStg.set("livePwd", pwd);
        this.livePwd = pwd;
      }
    },
    // Logout
    async logout() {
      // await userLogout();
      localStg.clear();
      this.$patch({});
      this.resetInfo();
      // clearToken();
      // 路由表重置
      // location.reload();
    },
  },
});