import axios from 'axios';
import type {AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError, InternalAxiosRequestConfig} from 'axios';
import {ElMessage} from 'element-plus';
import {localStg} from '@/scripts/store';
import {strConvertJson} from '@/scripts/utils';
// import * as dataJson from "/public/static/config.json";

export const showMessage = (status: number | string): string => {
  let message = '';
  switch (status) {
    case 400:
      message = '请求错误(400)';
      break;
    case 401:
      message = '未授权，请重新登录(401)';
      break;
    case 403:
      message = '拒绝访问(403)';
      break;
    case 404:
      message = '请求出错(404)';
      break;
    case 408:
      message = '请求超时(408)';
      break;
    case 500:
      message = '服务器错误(500)';
      break;
    case 501:
      message = '服务未实现(501)';
      break;
    case 502:
      message = '网络错误(502)';
      break;
    case 503:
      message = '服务不可用(503)';
      break;
    case 504:
      message = '网络超时(504)';
      break;
    case 505:
      message = 'HTTP版本不受支持(505)';
      break;
    default:
      message = `连接出错(${status})!`;
  }
  return `${message}，请检查网络或联系管理员！`;
};

// 页面请求数量
const requestSet = (val: number): void => {
  window.requestAdjust && window.requestAdjust(val);
};


const service: AxiosInstance = axios.create({
  baseURL: `${window.Config.UseSSL ? 'https://' : 'http://'}${window.Config.Host}:${window.Config.Port}`,
  timeout: 15000
});

// axios实例拦截请求
service.interceptors.request.use(
    (config: InternalAxiosRequestConfig) => {
      const token = localStg.get('token');
      if (token) {
        config.headers['Access-Token'] = `${token}`;
      }
      requestSet(1);
      return config;
    },
    (error: AxiosError) => {
      return Promise.reject(error);
    }
);

// axios实例拦截响应
service.interceptors.response.use(
    (response: AxiosResponse) => {
      requestSet(-1);
      if (response.status === 200) {
        if (response.data?.ErrCode === 'Unauthorized') {
          ElMessage({
            message: '当前登录已过期，请重新登录!',
            type: 'error',
            duration: 2000
          });
          localStg.clear();
          location.reload();
        } else if (response.data?.Result === false) {
          const reqData = strConvertJson(response.config.data || response.config.params);
          const cancelTip = reqData && reqData.NoTip;
          if (!cancelTip) {
            ElMessage({
              message: response.data.Info?.[0] || '查询无结果',
              type: 'error',
              duration: 2000
            });
          }
        }
        return response;
      }
      return response;
    },
    // 请求失败
    (error: any) => {
      const {response} = error;
      requestSet(-1);
      if (response) {
        // 请求已发出，但是不在2xx的范围
        ElMessage({
          message: showMessage(response.status),
          type: 'error',
          duration: 2000
        });
        return Promise.reject(response.data);
      }
      ElMessage({
        message: '网络连接异常,请稍后再试!',
        type: 'error',
        duration: 2000
      });
      return Promise.reject(error);
    }
);

const request = <IResponse>(config: AxiosRequestConfig): Promise<IResponse | null> => {
  const conf = config;
  return new Promise((resolve) => {
    service.request<any, AxiosResponse<IResponse>>(conf).then((res: AxiosResponse<IResponse>) => {
      const {
        data
      } = res;
      resolve(data);
    }).catch((err: AxiosError) => {
      resolve(null);
    });
  });
};

export function get<T = any>(config: AxiosRequestConfig): Promise<T | null> {
  return request({...config, method: 'GET'});
}

export function post<T = any>(config: AxiosRequestConfig): Promise<T | null> {
  return request({...config, method: 'POST'});
}

export default request;

export type {AxiosInstance, AxiosResponse};